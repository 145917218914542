import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PreferencesContext } from '../context/theme';
import { ButtonGeneral } from '../components/buttonGeneral';
import { deleteSeller } from '../api/authApi';
import { useNavigate } from 'react-router-dom';
import { CustomModal } from '../components/CustomModal';
import { auth } from '../firebase';
import { resetAuthState } from '../redux/features/sellerSlice';
import './styles/Settings.css';

const Settings = () => {
  const { theme } = useContext(PreferencesContext);
  const seller = useSelector((state) => state.seller.seller);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [logOutModal, setLogOutModal] = useState(false);
  const [contactUsModal, setContactUsModal] = useState(false);

  const deleteSellerAccount = async () => {
    try {
      await deleteSeller(seller?.seller_uid);
      dispatch(resetAuthState());
      await auth.signOut();
    } catch (error) {
      console.error('Error deleting seller account:', error.message);
    }
  };

  return (
    <div className='settings-container'>
      {!seller && (
        <div className='button-container'>
          <ButtonGeneral
            title='Login/Sign Up'
            color={theme.colors.pieBlue}
            border
            borderColor={theme.colors.onBackground}
            onPress={() => navigate('/login')}
            radius={25}
          />
        </div>
      )}
      {!seller?.admin && (
        <div className='button-container'>
          <ButtonGeneral
            title='Contact Us'
            color={theme.colors.lightGray}
            border
            borderColor={theme.colors.onBackground}
            onPress={() => setContactUsModal(true)}
            radius={25}
          />
        </div>
      )}
      {seller && (
        <div className='button-container'>
          <ButtonGeneral
            title='Log Out'
            color={theme.colors.background}
            border
            borderColor={theme.colors.onBackground}
            onPress={() => setLogOutModal(true)}
            radius={25}
          />
        </div>
      )}
      {seller && !seller?.admin && (
        <div className='button-container'>
          <ButtonGeneral
            title='Delete Account'
            color={theme.colors.darkRed}
            border
            borderColor={theme.colors.onBackground}
            onPress={() => setDeleteModal(true)}
            radius={25}
          />
        </div>
      )}

      {/* Contact Us Modal */}
      <CustomModal
        shown={contactUsModal}
        close={() => setContactUsModal(false)}
        component={
          <>
            <div className='contact-row'>
              <p className='settings-text'>Contact Us</p>
            </div>
            <div className='contact-row'>
              <p className='settings-text'>Email: </p>
              <a
                href={`mailto:info@pienet.net?subject=PIE Support${
                  seller?.seller_name ? ` - ${seller.seller_name}` : ''
                }`}
                className='contact-text'>
                info@pienet.net
              </a>
            </div>
          </>
        }
        buttonText='Close'
        onPress={() => setContactUsModal(false)}
        color={theme.colors.pieGreen}
        buttonTextColor={theme.colors.onBackground}
      />

      {/* Log Out Modal */}
      <CustomModal
        shown={logOutModal}
        close={() => setLogOutModal(false)}
        text='Are you sure you want to log out of your account?'
        buttonText='Log Out'
        onPress={async () => {
          dispatch(resetAuthState());
          await auth.signOut();
        }}
        color={theme.colors.background}
        buttonTextColor={theme.colors.onBackground}
        buttonL
        buttonLText='Cancel'
        buttonLColor={theme.colors.lightGray}
        buttonLTextColor={theme.colors.onBackground}
        buttonLHandlePress={() => setLogOutModal(false)}
      />

      {/* Delete Account Modal */}
      <CustomModal
        shown={deleteModal}
        close={() => setDeleteModal(false)}
        text='Are you sure you want to delete your account? This action cannot be undone.'
        buttonText='Delete'
        onPress={deleteSellerAccount}
        color={theme.colors.darkRed}
        buttonTextColor={theme.colors.background}
        buttonL
        buttonLText='Cancel'
        buttonLColor={theme.colors.lightGray}
        buttonLTextColor={theme.colors.onBackground}
        buttonLHandlePress={() => setDeleteModal(false)}
      />
    </div>
  );
};

export default Settings;
