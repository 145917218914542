import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PreferencesContext } from '../context/theme';
import { setProvider } from '../redux/features/sellerSlice';
import { ButtonGeneral } from '../components/buttonGeneral';
import { InputGeneral } from '../components/inputGeneral';
import './styles/ChoosePOS.css';
import cloverLogo from '../components/images/cloverLogo.jpeg';
import squareLogo from '../components/images/squareLogo.png';
import lightspeedLogo from '../components/images/lightspeedLogo.png';
import shopifyLogo from '../components/images/shopifyLogo.png';
import { logOutUser } from '../redux/features/authSlice';
import { auth } from '../firebase';
import { resetAuthState } from '../redux/features/sellerSlice';

const ChoosePOS = () => {
  const { theme } = useContext(PreferencesContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedPOS, setSelectedPOS] = useState(null);
  const [shopDomain, setShopDomain] = useState('');
  const [shopError, setShopError] = useState('');

  const handlePOSSelect = (title) => {
    setSelectedPOS(title);
    setShopDomain('');
    setShopError('');
  };

  const validateShopDomain = (shop) => {
    const trimmedShop = shop.trimEnd();

    const regex = /^[a-zA-Z0-9-]+\.myshopify\.com$/;

    return regex.test(trimmedShop);
  };

  const handleContinue = () => {
    setShopError('');

    if (selectedPOS) {
      const posLower = selectedPOS.toLowerCase();

      if (posLower === 'shopify') {
        if (!validateShopDomain(shopDomain)) {
          setShopError(
            'Please enter a valid Shopify shop domain (e.g., example-shop.myshopify.com).',
          );
          return;
        }
      }

      dispatch(setProvider(selectedPOS));

      navigate('/inventory', { state: { shopDomain } });
    }
  };

  const handleSkip = () => {
    dispatch(setProvider('Manual'));
    navigate('/inventory');
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      dispatch(logOutUser());
      dispatch(resetAuthState());
      navigate('/');
    } catch (err) {
      console.error('Error during logout:', err);
    }
  };

  const posSystems = [
    // { id: '1', title: 'Clover', imageUrl: cloverLogo },
    { id: '2', title: 'Square', imageUrl: squareLogo },
    { id: '3', title: 'Lightspeed', imageUrl: lightspeedLogo },
    { id: '4', title: 'Shopify', imageUrl: shopifyLogo },
  ];

  return (
    <div
      className='container'
      style={{ backgroundColor: theme.colors.background }}>
      <div className='logout-button-container'>
        <ButtonGeneral
          title='Settings'
          onPress={() => navigate('/settings')}
          color={theme.colors.softGray}
          borderColor={theme.colors.onBackground}
          border
          margin={5}
        />
      </div>

      <h2 className='title'>Choose which POS system your business uses</h2>
      <div className='pos-list'>
        {posSystems.map((item) => (
          <div
            key={item.id}
            className={`image-container ${selectedPOS === item.title ? 'selected' : ''}`}
            onClick={() => handlePOSSelect(item.title)}
            style={{
              borderColor:
                selectedPOS === item.title ? theme.colors.pieGreen : theme.colors.onBackground,
            }}>
            <img
              src={item.imageUrl}
              alt={`${item.title} logo`}
              className='image'
            />
          </div>
        ))}
      </div>

      {selectedPOS && selectedPOS.toLowerCase() === 'shopify' && (
        <div className='shop-input-container'>
          <InputGeneral
            label='Enter your Shopify Shop Domain:'
            placeholder='example-shop.myshopify.com'
            value={shopDomain}
            onChange={(e) => setShopDomain(e.target.value)}
            errors={shopError}
            autoCapitalize='none'
            max={255}
            type='text'
          />
        </div>
      )}

      <div className='button-container'>
        <ButtonGeneral
          title='Continue'
          onPress={handleContinue}
          disabled={
            !selectedPOS || (selectedPOS.toLowerCase() === 'shopify' && shopDomain.trim() === '')
          }
          margin={5}
        />
        {/* <ButtonGeneral
          title='Skip'
          onPress={handleSkip}
          color={theme.colors.softGray}
          borderColor={theme.colors.onBackground}
          border
          margin={5}
        /> */}
      </div>
    </div>
  );
};

export default ChoosePOS;
