import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthRedirect = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.seller.user);
  const seller = useSelector((state) => state.seller.seller);

  useEffect(() => {
    if (user) {
      if (seller && seller.oauthTokens && seller.oauthTokens.length > 0) {
        navigate('/inventory', { replace: true });
      } else {
        navigate('/choose-pos', { replace: true });
      }
    } else {
      navigate('/', { replace: true });
    }
  }, [user, seller, navigate]);

  return null; // This component doesn't render anything
};

export default AuthRedirect;
