import client from '../controller/client';

export const createSeller = async (sellerData) => {
  try {
    const response = await client.post('/api/_auth/apply', sellerData);
    return response.data;
  } catch (error) {
    console.error('Error applying to be a seller:', error);
    throw new Error('Failed to apply to be a seller');
  }
};

export const getSeller_ = async (uid) => {
  try {
    const response = await client.get(`/api/_auth/seller_/${uid}`);

    return response.data;
  } catch (error) {
    console.error('Error getting a seller:', error);
    throw new Error('Failed to get a seller');
  }
};

export const updateShopifyData = async () => {
  try {
    const response = await client.post(`/api/_auth/shopify-inventory`);
    return response.data;
  } catch (error) {
    console.error('Error updating Shopify data:', error);
    throw new Error('Failed to update Shopify data');
  }
};

export const updateLightspeedData = async () => {
  try {
    const response = await client.post(`/api/_auth/lightspeed-inventory`);
    return response.data;
  } catch (error) {
    console.error('Error updating Lightspeed data:', error);
    throw new Error('Failed to update Lightspeed data');
  }
};

export const updateSquareData = async () => {
  try {
    const response = await client.post(`/api/_auth/square-inventory`);
    return response.data;
  } catch (error) {
    console.error('Error updating Square data:', error);
    throw new Error('Failed to update Square data');
  }
};

export const updateCloverData = async () => {
  try {
    const response = await client.post(`/api/_auth/clover-inventory`);
    return response.data;
  } catch (error) {
    console.error('Error updating Clover data:', error);
    throw new Error('Failed to update Clover data');
  }
};

export const deleteSeller = async (uid) => {
  try {
    const response = await client.delete(`/api/_auth/delete-seller/${uid}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting a seller:', error);
    throw new Error('Failed to delete seller');
  }
};
